/**
 * Copyright (C) Sitevision AB 2002-2022, all rights reserved
 *
 * @author Sjostrand
 */
(function ($) {
  $.fn.pagination = function (options) {
    if (typeof options === 'number') {
      // Possible attempt to use old version plugin.
      // Fall back to no-js pagination.
      return;
    }

    const $paginationElements = this;
    const hitsContainerSelector = `.${options.hitContainer}`;
    const totalPages = Math.ceil((options.hitCount || 0) / options.hitsPerPage);
    const mainContainerSelector = `${options.hitContainer.replace(
      'hits',
      'result'
    )}`;
    const mainContainer = document.getElementById(mainContainerSelector);
    let currentPage = 1;

    function getPagingLink(number) {
      return `<li><a
        href="#${mainContainerSelector}"
        data-page="${number}">
        <span class="env-assistive-text">${options.text.goToPage}</span>
        ${number}
        </a></li>`;
    }

    function getCurrentSpan(number) {
      return `<li><span aria-current="page" class="current"><span class="env-assistive-text">${options.text.currentPage}</span> ${number}</span></li>`;
    }

    function generatePagingNumberedItemsHTML(rangeStart, rangeEnd) {
      let html = '';
      let i = 1;

      if (rangeStart < 2) {
        for (i = 1; i < rangeStart; i++) {
          if (i === currentPage) {
            html += getCurrentSpan(i);
          } else {
            html += getPagingLink(i);
          }
        }
      } else {
        html += getPagingLink(i);
        html += `<span>...</span>`;
      }

      for (i = rangeStart; i <= rangeEnd; i++) {
        if (i === currentPage) {
          html += getCurrentSpan(i);
        } else {
          html += getPagingLink(i);
        }
      }

      if (rangeEnd >= totalPages - 2) {
        for (i = rangeEnd + 1; i <= totalPages; i++) {
          html += getPagingLink(i);
        }
      } else {
        html += `<span>...</span>`;
        html += getPagingLink(totalPages);
      }

      return html;
    }

    function generatePaginationHTML(ranges) {
      const linkTarget = `#${mainContainerSelector}`;
      const prevText = options.text.prev;
      const nextText = options.text.next;

      let html = '';
      if (currentPage <= 1) {
        html += `<li><span class="current prev">${prevText}</span></li>`;
      } else {
        html += `<li><a href="${linkTarget}" class="prev" data-page="prev">${prevText}</a></li>`;
      }

      html += generatePagingNumberedItemsHTML(
        ranges.rangeStart,
        ranges.rangeEnd
      );
      if (currentPage >= totalPages) {
        html += `<li><span class="current next">${nextText}</span></li>`;
      } else {
        html += `<li><a href="${linkTarget}" class="next" data-page="next">${nextText}</a></li>`;
      }

      return `<nav aria-describedby="${options.hitContainer}_pagination"><h2 class="env-assistive-text" id="${options.hitContainer}_pagination">${options.text.paginationLabel}</h2><ul class="pagination env-list env-list--horizontal env-flex--wrap">${html}</ul></nav>`;
    }

    function getRanges() {
      const pagesOnEachSide = 2; //two on each side
      let rangeStart = currentPage - pagesOnEachSide;
      let rangeEnd = currentPage + pagesOnEachSide;

      if (rangeEnd > totalPages) {
        rangeEnd = totalPages;
        rangeStart = totalPages - pagesOnEachSide * 2;
        rangeStart = rangeStart < 1 ? 1 : rangeStart;
      }

      if (rangeStart <= 1) {
        rangeStart = 1;
        rangeEnd = Math.min(pagesOnEachSide * 2 + 1, totalPages);
      }

      return {
        rangeStart: rangeStart,
        rangeEnd: rangeEnd,
      };
    }

    function clickHandler(e) {
      e.preventDefault();

      const $target = $(e.target);
      const pageNumber = $target.data('page');

      switch (pageNumber) {
        case 'prev':
          currentPage--;
          break;
        case 'next':
          currentPage++;
          break;
        default:
          currentPage = pageNumber;
      }

      const url = options.baseUrl + (currentPage - 1) * options.hitsPerPage;

      $paginationElements.html(generatePaginationHTML(getRanges()));
      sv.PortletUtil.doGetWithFade(hitsContainerSelector, url, () => {
        mainContainer.scrollIntoView();
      });
    }

    $paginationElements.html(generatePaginationHTML(getRanges()));
    $(mainContainer).off('click', '.pagination a').on('click', '.pagination a', clickHandler);
  };
})(window.$svjq);
